import React from "react";
import { parse, format } from "date-fns";
import { Link } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import { CardTheme, colors } from "../style/theme";
import { mq, parseDate, formatDate } from "../utils/helper";

const { FeedTitle, Creatorlink, Cardbox, CardImage, CardDetails } = CardTheme;

export default function PostCard({ node }) {
  const { title, date, path, image, desc, author } = node.frontmatter;
  return (
    <Cardbox
      css={{
        minHeight: "auto",
        textDecoration: "none",
        color: colors.black,
      }}
      as={Link}
      to={path}
    >
      <CardImage>
        <img
          src={image.childImageSharp.fluid.src}
          alt={title}
          css={{ marginBottom: 0, height: "100%" }}
        />
      </CardImage>
      <CardDetails
        css={{
          paddingBottom: 0,
        }}
      >
        <FeedTitle>{title}</FeedTitle>
        <small
          css={{
            opacity: 0.85,
          }}
        >
          {format(new Date(date), "yyyy-MM-dd")}
        </small>
        <small>
          {" | "} {author}
        </small>
        <p>{desc}</p>
      </CardDetails>
    </Cardbox>
  );
}

PostCard.propTypes = {
  node: PropTypes.object,
};
