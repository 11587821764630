import React from "react";
import { graphql } from "gatsby";
import PostCard from "../components/post-card";
import SEO from "../components/seo";
import Layout from "../components/layout";

function Blog({ data }) {
  const posts = data.allMdx.edges.map((p) => p.node);
  return (
    <Layout>
      <SEO title="المدوّنة" />
      {posts.map((node) => (
        <PostCard node={node} key={node.id} />
      ))}
    </Layout>
  );
}

export default Blog;

export const pageQuery = graphql`
  query Blog {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { isBlog: { eq: true } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 600)
          frontmatter {
            title
            date
            path
            isBlog
            author
            desc
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
